const isString = (string) => {
  if (typeof string === "string") return true;
  else return false;
};

const isEmpty = (string) => {
  if (string.trim() === "") return true;
  else return false;
};

const isEmail = (email) => {
  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(emailRegEx)) return true;
  else return false;
};

export const validateLoginData = (data) => {
  let errors = {};

  // Email Validation
  if (!isString(data.email)) errors.email = INVALID_TYPE_MESSAGE;
  else if (isEmpty(data.email)) errors.email = EMPTY_INPUT_MESSAGE;
  else if (!isEmail(data.email)) errors.email = VALID_EMAIL_MESSAGE;
  // Password Validation
  if (!isString(data.password)) errors.password = INVALID_TYPE_MESSAGE;
  else if (isEmpty(data.password)) errors.password = EMPTY_INPUT_MESSAGE;

  return { errors, valid: Object.keys(errors).length === 0 ? true : false };
};

export const validateSignUpData = (data) => {
  let errors = {};

  // Email Validation
  if (!isString(data.email)) errors.email = INVALID_TYPE_MESSAGE;
  else if (isEmpty(data.email)) errors.email = EMPTY_INPUT_MESSAGE;
  else if (!isEmail(data.email)) errors.email = VALID_EMAIL_MESSAGE;
  // First Name Validation
  if (!isString(data.firstName)) errors.firstName = INVALID_TYPE_MESSAGE;
  else if (isEmpty(data.firstName)) errors.firstName = EMPTY_INPUT_MESSAGE;
  // Last Name Validation
  if (!isString(data.lastName)) errors.lastName = INVALID_TYPE_MESSAGE;
  else if (isEmpty(data.lastName)) errors.lastName = EMPTY_INPUT_MESSAGE;
  // Password Validation
  if (isEmpty(data.password)) errors.password = INVALID_TYPE_MESSAGE;
  else if (isEmpty(data.password)) errors.password = EMPTY_INPUT_MESSAGE;
  // Confirm Password Validation
  if (data.password !== data.confirmPassword)
    errors.confirmPassword = PASSWORD_MATCH_MESSAGE;

  return { errors, valid: Object.keys(errors).length === 0 ? true : false };
};

export const validateIfCourseIsRegistered = (courseId, registeredCourses) => {
  if (
    Array.isArray(registeredCourses) &&
    registeredCourses.includes(courseId)
  ) {
    return true;
  } else {
    return false;
  }
};

const INVALID_TYPE_MESSAGE = "Invalid format";
const EMPTY_INPUT_MESSAGE = "Must not be empty";
const VALID_EMAIL_MESSAGE = "Must be a valid email address";
const PASSWORD_MATCH_MESSAGE = "Passwords must be the same";
