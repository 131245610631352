import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { Notifications, AccountCircle, Home } from "@mui/icons-material/";

import {
  Badge,
  ClickAwayListener,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
} from "@mui/material";
import { auth } from "../api/firebase";

import MerckLogo from "../assets/icons/merck_logo.svg";
import { useNavigate } from "react-router-dom";
import UserProfileModal from "./Modals/UserProfileModal";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  position: "fixed",
  background: "linear-gradient(#0067B0, #00C0F3)",
  zIndex: theme.zIndex.drawer + 1,
}));

export default function MainAppBar() {
  const navigate = useNavigate();

  const [openAccountSettings, setAccountSettings] = useState(false);
  const anchorRefProfile = useRef(null);

  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState(null);
  const open = Boolean(anchorNotificationsEl);

  const [openProfile, setOpenProfile] = useState(false);
  const handleOpenProfile = () => setOpenProfile(true);
  const handleCloseProfile = () => setOpenProfile(false);

  const handleNotificationsClick = (event) => {
    setAnchorNotificationsEl(event.currentTarget);
  };
  const handleNotificationsClose = () => {
    setAnchorNotificationsEl(null);
  };

  const handleAccountSettingsToggle = (event) => {
    setAccountSettings((prevOpen) => !prevOpen);
  };

  const handleAccountSettingsClose = (event) => {
    if (
      anchorRefProfile.current &&
      anchorRefProfile.current.contains(event.target)
    ) {
      return;
    }

    setAccountSettings(false);
  };

  const handleProfileClick = () => {
    setOpenProfile(true);
  };

  const handleLogoutClick = () => {
    auth()
      .signOut()
      .then(() => {
        // Success
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleProfileListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAccountSettings(false);
    }
  }

  return (
    <>
      <AppBar position="absolute">
        <Toolbar>
          <a href="/" style={{ flex: 1 }}>
            <img src={MerckLogo} height="50" alt="Main Header Logo" />
          </a>
          <IconButton onClick={() => navigate("/")} color="inherit">
            <Home />
          </IconButton>

          <IconButton
            id="notifications-button"
            aria-controls={open ? "notifications-list" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleNotificationsClick}
            color="inherit"
          >
            <Badge badgeContent={0}>
              <Notifications />
            </Badge>
          </IconButton>

          <IconButton
            color="inherit"
            ref={anchorRefProfile}
            aria-haspopup="true"
            aria-controls={openAccountSettings ? "menu-list-grow" : undefined}
            onClick={handleAccountSettingsToggle}
          >
            <AccountCircle />
          </IconButton>

          <Menu
            id="notifications-list"
            anchorEl={anchorNotificationsEl}
            open={open}
            onClose={handleNotificationsClose}
            PaperProps={{
              style: {
                width: 300,
              },
            }}
          >
            <MenuItem
              disabled
              divider
              sx={{ "&.Mui-disabled": { opacity: 1 }, fontWeight: "bold" }}
            >
              Notifications
            </MenuItem>
            <MenuItem disabled>No Notifications</MenuItem>
          </Menu>

          <Popper
            open={openAccountSettings}
            anchorEl={anchorRefProfile.current}
            role="button"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleAccountSettingsClose}>
                    <MenuList
                      autoFocusItem={openAccountSettings}
                      id="profile-menu-list-grow"
                      onKeyDown={handleProfileListKeyDown}
                    >
                      <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                      <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>

      <UserProfileModal open={openProfile} onClose={handleCloseProfile}/>
    </>
  );
}