/**
 * Function decides what boolean to set as the initial state of a page element
 * since some elements have no restrictions to move foward to
 * @param editable  Is Page Element editable
 * @param type      Page Element type
 * @returns         returns a boolean for the initial state of the page element
 */
export const getCanProceedInitialState = (editable, type, isFinished) => {
  // We can always move foward if it's an editable element
  if (editable || isFinished) return true;
  // State each page element here
  switch (type) {
    case "main":
      return true;
    case "block":
      return true;
    case "quiz":
      return false;
    case "video":
      return false;
    case "case_study":
      return true;
    case "image":
      return true;
    default:
      return true;
  }
};

export const getEditable = (search, userRole) => {
  const query = new URLSearchParams(search);
  const editableQuery = query.get("mode");
  return editableQuery === "edit" && userRole === "moderator"
    ? true
    : false;
};

