import { Button, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AssignmentBanner from "../../assets/images/assignment_banner.svg";
import FilePreviewModal from "../Modals/FilePreviewModal";

export default function AssignmentElement() {
  const [previewOpen, setPreviewOpen] = useState("");
  const assignments = useSelector((state) => state.moduleData.assignments);

  const handleClick = (uri) => {
    window.open(uri, "_blank");
  };

  const handlePreviewOpen = (id) => setPreviewOpen(id);
  const handlePreviewClose = () => setPreviewOpen("");

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        component={"img"}
        alignSelf="stretch"
        src={AssignmentBanner}
        alt="Quiz Banner"
      />
      <Typography
        variant="h3"
        sx={{ mt: 2, fontWeight: "bold" }}
        color="primary"
        align="center"
      >
        Assignments/Templates
      </Typography>

      {assignments.map((assignment, index) => {
        const { id, title, uri } = assignment;

        return (
          <Box
            key={`container-${index}`}
            width="100%"
            my={2}
            px={5}
            maxWidth={800}
          >
            <FilePreviewModal
              key={`file-preview-${index}`}
              open={previewOpen === id}
              onClose={handlePreviewClose}
              src={uri}
            />
            <Typography key={`title-${index}`} variant="h5" fontWeight="bold">
              {title}
            </Typography>
            <Button
              key={`preview-button-${index}`}
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: 5,
                fontWeight: "bold",
                textTransform: "none",
                mt: 1,
                mr: 1,
              }}
              onClick={() => handlePreviewOpen(id)}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              color="secondary"
              key={`download-button-${index}`}
              sx={{
                borderRadius: 5,
                fontWeight: "bold",
                textTransform: "none",
                mt: 1,
              }}
              onClick={() => handleClick(uri)}
            >
              Download
            </Button>
            <Divider
              key={`divider-${index}`}
              sx={{ my: 2, borderBottomWidth: 2, borderColor: "primary.main" }}
            />
          </Box>
        );
      })}
    </Box>
  );
}
