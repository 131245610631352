import {
  Box,
  Icon,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../providers/UserProvider";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 800,
  minHeight: 360,
  bgcolor: "#FFF",
  boxShadow: 12,
  p: 2,
};

export default function UserProfileModal({ open, onClose }) {
  const user = useContext(UserContext);
  console.log(user);

  if (!user.data) {
    return;
  }

  const { email, firstName, lastName } = user.data;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="profile-modal-title"
      aria-describedby="profile-modal-description"
    >
      <Box sx={style}>
        <IconButton size="large" sx={{ alignSelf: "flex-end" }} onClick={onClose}>
          <Icon component={CloseIcon} />
        </IconButton>
        <Box sx={{ display: "flex", flexDirection: "column", px: 4 }}>
          <Typography id="profile-modal-title" variant="h3" fontWeight="bold">
            My Profile
          </Typography>
          <Typography
            id="profile-modal-description"
            fontSize={18}
            sx={{ mt: 1, mb: 2 }}
          >
            View your information here.
          </Typography>
          <TextField
            id="email-read-only-input"
            variant="standard"
            defaultValue={email}
            label="Email"
            sx={{ width: 360, mb: 2 }}
            InputProps={{ readOnly: true }}
          />
          <TextField
            id="name-read-only-input"
            variant="standard"
            defaultValue={`${firstName} ${lastName}`}
            label="Name"
            sx={{ width: 360, mb: 2 }}
            InputProps={{ readOnly: true }}
          />
        </Box>
      </Box>
    </Modal>
  );
}

UserProfileModal.propTypes = {
  /** If true, the component is shown. */
  open: PropTypes.bool,
  /**  Callback fired when the component requests to be closed. */
  onClose: PropTypes.func,
};
