import React from "react";
import { Box, Card, CardContent, CardMedia } from "@mui/material";
import { styled } from "@mui/system";
import MerckLogo from '../assets/icons/merck_logo.svg';

const bannerHeight = 256;

const FiCardMedia = styled(CardMedia)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  height: "100%",
  width: "100%",
}));

const FiCardContent = styled(CardContent)(({ theme }) => ({
  position: "relative",
  height: "100%",
  backgroundColor: "transparent",
}));

export default function BannerLogoCard({imageUri}) {
  return (
    <Card sx={{ position: "relative", boxShadow: 0, height: bannerHeight }}>
      <FiCardMedia image={imageUri} />
      <FiCardContent
        sx={{ color: "#ffffff", backgroundColor: "rgba(0,0,0,.24)" }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: "100%",
            alignItems: "center",
            justifyContent: 'flex-end'
          }}
        >
          <img src={MerckLogo} style={{marginBottom: 24}} alt="Marketing Way" height="92px"/>
        </Box>
      </FiCardContent>
    </Card>
  );
}
