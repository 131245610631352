import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { MENU_FOCUS_MODULE } from "../../constants/ModuleDataConstants";
import {
  setModuleMenuFocus,
  setSelectedIndex,
} from "../../actions/moduleDataActions";

export default function CaseStudiesElement() {
  const moduleId = useSelector((state) => state.moduleData.moduleId);
  const pages = useSelector((state) => state.moduleData.pages);
  const caseStudyList = pages.filter((page) => page.type === "case_study");
  const courseData = useSelector((state) => state.userCourseData.courseData);
  const dispatch = useDispatch();

  const handleClick = async (index) => {
    dispatch(setSelectedIndex(index));
    dispatch(setModuleMenuFocus(MENU_FOCUS_MODULE));
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography
        variant="h3"
        sx={{ mt: 2, fontWeight: "bold" }}
        color="primary"
        align="center"
      >
        Case Studies
      </Typography>
      <Typography mt={1} mb={1} px={2} fontWeight="bold" textAlign="center">
        Watch case studies on your own time as it isn't required to continue in
        the module
      </Typography>
      {caseStudyList.map((item) => {
        const isEnabled = courseData[moduleId].pages[item.index].enabled;
        const opacity = isEnabled ? 1.0 : 0.1;

        return (
          <Card
            sx={{
              display: "flex",
              width: 720,
              mx: 2,
              mt: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <CardContent sx={{ flex: "1 1 auto" }}>
                <Typography fontSize={24} fontWeight="bold">
                  {item.title}
                </Typography>
              </CardContent>
              <CardActions>
                <IconButton
                  disabled={!isEnabled}
                  onClick={() => handleClick(item.index)}
                >
                  <PlayCircleOutlineIcon
                    sx={{
                      color: "text.primary",
                      opacity: opacity,
                      fontSize: 54,
                    }}
                  />
                </IconButton>
              </CardActions>
            </Box>
          </Card>
        );
      })}
    </Box>
  );
}

const object = {
  questions: [
    {
      type: "radio",
      answer: "True",
      choices: ["True", "False"],
      content:
        "True or False: Traditional marketing focuses on broad offerings over large consumer segments, modern marketing leverages available data in real-time to optimize and personalize the consumer experience in a more meaningful way. (1.1 Part II)",
    },
    {
      answer: "83%",
      content:
        "According to McKinsey, what percentage of global CEOs said they look to marketing to be a major driver for most or all of their company’s growth agenda? (1.1 Part II)",
      choices: ["25%", "52%", "83%", "42%"],
      type: "radio",
    },
    {
      content:
        "True or False: Evolution of work will continue to shift from in-office to virtual by almost 50% across the world between now and 2025. (1.2 Part I)",
      answer: "True",
      type: "radio",
      choices: ["True", "False"],
    },
    {
      answer:
        "Clever like Google, Easy like Apple, Personal like WhatsApp, Visual like Instagram, Immediate like Amazon, Human like Disney, Connected like Facebook, Entertaining like YouTube",
      choices: [
        "Clever like Google, Easy like Apple, Personal like WhatsApp, Visual like Instagram, Immediate like Amazon, Human like Disney, Connected like Facebook, Entertaining like YouTube",
        "Easy like Google, Simple like Apple, Instant like WhatsApp, Social like Instagram, Efficient like Amazon, Magic like Disney, Connected like Facebook, Amusing like YouTube",
        "Informational like Google, Innovative like Apple, Visual like WhatsApp, Expressive like Instagram, Responsive like Amazon, Youthful like Disney, Social like Facebook, Funny like YouTube",
        "None of the above",
      ],
      type: "radio",
      content:
        "As you learned from this module, what brand experiences do you expect from the following brands as specifically mentioned? (1.2 Part I)",
    },
    {
      choices: ["True", "False"],
      content:
        "True or False: Companies like Bombas®, Cuyana®, and Glossier® lack offering consumers high-levels of consumer hyper-personalization. (1.2 Part II)",
      answer: "False",
      type: "radio",
    },
    {
      answer: "Brilliant Earth®",
      content:
        "What brand allows you to customize wedding bands entirely online with 100% ethically sourced gemstones? (1.2 Part III",
      choices: [
        "Brilliant Earth®",
        "Cartier®",
        "Pandora®",
        "None of the above",
      ],
      type: "radio",
    },
    {
      content:
        "Singapore Airlines integrates pre-flight, in-flight, and post-flight experiences onto their website and mobile app, creating a smoother journey all around (1.3 Part I",
      choices: ["True", "False"],
      type: "radio",
      answer: "True",
    },
    {
      choices: ["Jim Stengel", "Albert Einstein", "Thomas Edison", "Elon Musk"],
      content:
        "Who said the following quote, “If there is a better way to do it, find it”? (1.3 Part II)",
      answer: "Thomas Edison",
      type: "radio",
    },
    {
      answer: "India",
      type: "radio",
      choices: ["Japan", "Mexico", "India", "All of the above"],
      content:
        "Where would McDonald’s have a hard time selling franchises if they had stuck with the same marketing strategy as the one they had in the U.S.? (1.3 Part III)",
    },
    {
      choices: [
        "Differentiated in the eyes of consumers",
        "Connects at an emotional level with consumers",
        "Built into routine",
        "Is consumed",
        "A & B",
      ],
      answer: "A & B",
      type: "radio",
      content:
        "As we think about some of the differences between a Brand and a particular product, we must understand that a Brand is: (1.4 Part I)",
    },
    {
      choices: ["True", "False"],
      content:
        "True or False: Price refers to the actual amount the consumer is expected to pay for a product. (1.4 Part I)",
      answer: "True",
      type: "radio",
    },
    {
      choices: [
        "Distribution",
        "Value chain costs",
        "Competitor’s pricing",
        "Product Uniqueness",
        "All of the above",
      ],
      content: "What variables can impact price? (1.4 Part I)",
      type: "radio",
      answer: "All of the above",
    },
    {
      content:
        "Starbucks® has been responsible for creating the concept of the third place between home and work where people can relax, enjoy a cup of coffee and experience the inviting ambiance. (1.4 Part II)",
      choices: ["True", "False"],
      type: "radio",
      answer: "True",
    },
    {
      answer: "A & C",
      type: "radio",
      content:
        "As marketing continues to evolve with time, some industry leaders suggest that the 4 P’s be reevaluated. A popular suggested change is to add 2 more P’s to the mix. What are the 2 additional P’s? (1.4 Part III)",
      choices: ["Process", "Platform", "People", "A & B", "A & C"],
    },
    {
      answer: "All of the above",
      content: "Why did Toys R’ Us fail at executing its 4 P’s? (1.4 Part III)",
      choices: [
        "Failure to deliver the shopping experience",
        "Didn’t evolve as consumers and the retail landscape were evolving",
        "Lack of innovation",
        "All of the above",
      ],
      type: "radio",
    },
    {
      type: "radio",
      answer: "X-Men",
      choices: ["Avengers", "Eternals", "DC Comics", "X-Men"],
      content:
        "What characters did Elon Musk name his robots after at Tesla’s California factory? (1.5 Part III)",
    },
    {
      content:
        "True or False: Albert Einstein said, “It’s not the strongest of the species that survive, nor the most intelligent, but the one most responsive to change.”",
      type: "radio",
      answer: "False",
      choices: ["True", "False"],
    },
    {
      type: "radio",
      choices: ["True", "False"],
      answer: "True",
      content:
        "True or False: Being agile in marketing helps produce clear-cut results and consistent growth by quickly responding to changing consumer expectations. (1.6 Part II)",
    },
    {
      choices: [
        "Political, Economic, Science, Technological, Legal, Ecosystem",
        "People, Economy, Social, Technological, Legal, Ecosystem",
        "Political, Economic, Social, Technological, Legal, Environmental",
        "People, Earnings, Science, Technological, Legal, Environmental",
      ],
      answer:
        "Political, Economic, Social, Technological, Legal, Environmental",
      content: "What does PESTLE stand for? (1.6 Part III)",
      type: "radio",
    },
    {
      answer: "“War Rooms”",
      content:
        "What term is used by agile marketers to drive quick decision making? (1.6 Part III)",
      type: "radio",
      choices: [
        "“Agile Meetings”",
        "“War Rooms”",
        "“Sprint Sessions”",
        "None of the above",
      ],
    },
    {
      answer: "Dove®",
      type: "radio",
      choices: ["Olay®", "Aveeno®", "Dove®", "Neutrogena®"],
      content:
        "Which brand will no longer include the word “normal” in any of their products? (1.6 Part IV)",
    },
    {
      choices: ["25%", "40%", "50%", "65%"],
      answer: "50%",
      type: "radio",
      content:
        "By rapidly iterating and testing content within a two-week cycle, Walmart’s agile practices effectively increased speed to market by what percentage? (1.6 Part IV)",
    },
  ],
  title: "Module 1 Knowledge Check",
};
