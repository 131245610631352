import { validateLoginData, validateSignUpData } from "../util/validators";
import { auth, db } from "./firebase";

const DEFAULT_ROLE = "user";
const NO_PERMISSION_MESSAGE = "You do not have permission to modify this value";

export const signInUser = async (email, password) => {
  const user = {
    email: email,
    password: password,
  };

  const { errors, valid } = validateLoginData(user);
  if (!valid) {
    throw errors;
  }

  try {
    const userCredentials = await auth().signInWithEmailAndPassword(
      user.email,
      user.password
    );
    console.log(`${userCredentials.user} is signed in`);
  } catch (error) {
    errors.invalid = error.message;
    throw errors;
  }
};

export const createUser = async (
  firstName,
  lastName,
  email,
  password,
  confirmPassword
) => {
  const newUser = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: password,
    confirmPassword: confirmPassword,
  };

  const { valid, errors } = validateSignUpData(newUser);

  if (!valid) {
    throw errors;
  }

  try {
    const userCredential = await auth().createUserWithEmailAndPassword(
      email,
      password
    );

    await db.collection("users").doc(userCredential.user.uid).set({
      firstName: firstName,
      lastName: lastName,
      createdAt: new Date().toISOString(),
      role: DEFAULT_ROLE,
      registeredCourses: [],
    });
    console.log("User Added");

    return { valid: true };
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const getUser = async (uid) => {
  try {
    const userDoc = await db.collection("users").doc(uid).get();
    return userDoc.data();
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (uid, data) => {
  if (typeof data.createAt !== "undefined")
    throw new Error(NO_PERMISSION_MESSAGE);

  try {
    await db.collection("users").doc(uid).update(data);
  } catch (error) {
    throw error;
  }
};
