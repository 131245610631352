import React, { createContext, useEffect, useState } from "react";
import { auth, db } from "../api/firebase";

export const UserContext = createContext({
  credentials: null,
  data: null,
  loading: true,
});

function UserProvider(props) {
  const [credentials, setCredentials] = useState({ data: null, loading: true });
  const [data, setData] = useState({ data: null, loading: true });

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((user) => {
      setCredentials({ data: user, loading: false });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Check if user doesn't exist and we finished loading
    if (credentials.data === null && credentials.loading === false) {
      setData({ data: null, loading: false });
      return;
    }
    // Check if user doesn't exist but we haven't finished loading
    if (credentials.data === null) {
      return;
    }

    const unsubscribe = db
      .collection("users")
      .doc(credentials.data.uid)
      .onSnapshot((doc) => {
        setData({ data: doc.data(), loading: false });
      });

    return () => {
      unsubscribe();
    };
  }, [credentials.data, credentials.loading]);

  const values = {
    credentials: credentials.data,
    data: data.data,
    loading:
      credentials.loading === false && data.loading === false ? false : true,
  };

  return (
    <UserContext.Provider value={values}>{props.children}</UserContext.Provider>
  );
}
export default UserProvider;
