import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import CircularStatic from "./CircularProgressWithLabel";

export default function WelcomeCard({ name, progress }) {
  return (
    <Card sx={{ alignSelf: "stretch", boxShadow: 0, borderRadius: 0,  }}>
      <Box sx={{backgroundColor: "#003057", py:0.5}}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize={32}
          color="#FFF"
        >
          Welcome {name}!
        </Typography>
      </Box>

      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          my:4
        }}
      >
        <CircularStatic progress={progress.result} />
        <Box>
          <Typography variant="h4" sx={{ fontWeight: "bold" }} component="div">
            Of Course
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: "bold" }} component="div">
            Completed
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }} component="div">
            {progress.remainder} modules remaining
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

WelcomeCard.propTypes = {
  name: PropTypes.string.isRequired,
};
