import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PlaceHolderImage from "../assets/images/placeholder_image.jpg";

export default function CourseCard({ imageUri, course, onClick }) {
  return (
    <Card sx={{ boxShadow: 0, borderRadius: 0 }}>
      <CardContent sx={{ display: "flex", p: 0 }}>
        <Box
          component="img"
          src={imageUri}
          sx={{
            width: "30%",
            aspectRatio: "2/1",
            objectFit: "cover",
            backgroundPosition: "center",
          }}
          alt="Module Image"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            flex: 1,
            mx: 5,
          }}
        >
          <Typography variant="h4" fontWeight="bold" component="div">
            {course.title}
          </Typography>
        </Box>
        <CardActions>
          <IconButton sx={{ mr: 4 }} onClick={onClick}>
            <PlayCircleOutlineIcon
              sx={{ color: "text.primary", fontSize: 54 }}
            />
          </IconButton>
        </CardActions>
      </CardContent>
      <Box sx={{ backgroundColor: "#FF0000", width: "100%", height: 10 }} />
    </Card>
  );
}

CourseCard.defaultProps = {
  imageUri: PlaceHolderImage,
};

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
