import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import UserProvider from "./providers/UserProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material/";
import GothamMedium from "./assets/fonts/GothamMedium.ttf";
import { Provider } from "react-redux";
import rootReducer from "./reducers/rootReducer";
import { createStore } from "redux";

const fontStyleOverride = `
  @font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Gotham'), local('GothamMedium'), url(${GothamMedium}) format('ttf');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
`;

const fontFamily = ["Gotham", "Arial"].join(",");
const fontFamilyTemp = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

const theme = createTheme({
  palette: {
    primary: {
      main: "#003057",
    },
    secondary: {
      main: "#F0A324",
    },
    text: {
      primary: "#003057",
    },
  },
  typography: {
    fontFamily: fontFamilyTemp,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          overflow: "hidden",
        },
      `,
    },
  },
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <CssBaseline />
          <App />
        </UserProvider>
      </ThemeProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
