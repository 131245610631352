import React from "react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "../../util/editorJsTools";

const ReactEditorJS = createReactEditorJS();

export default function BlockElement() {
  return (
    <ReactEditorJS
      tools={EDITOR_JS_TOOLS}
      minHeight={0}
      defaultValue={{ time: new Date().getTime(), blocks: blockData }}
    ></ReactEditorJS>
  );
}


const blockData = [{
  type: "header",
  data: {
    text: "Editor.js",
    level: 2
  }
},
{
  type : 'paragraph',
  data : {
    text : 'Hey. Meet the new Editor. On this page you can see it in action — try to edit this text. Source code of the page contains the example of connection and configuration.'
  }
},
{
  type: "header",
  data: {
    text: "Key features",
    level: 3
  }
},
{
  type : 'list',
  data : {
    items : [
      'It is a block-styled editor',
      'It returns clean data output in JSON',
      'Designed to be extendable and pluggable with a simple API',
    ],
    style: 'unordered'
  }
},
{
  type: "header",
  data: {
    text: "What does it mean «block-styled editor»",
    level: 3
  }
},
{
  type : 'paragraph',
  data : {
    text : 'Workspace in classic editors is made of a single contenteditable element, used to create different HTML markups. Editor.js <mark class=cdx-marker>workspace consists of separate Blocks: paragraphs, headings, images, lists, quotes, etc</mark>. Each of them is an independent contenteditable element (or more complex structure) provided by Plugin and united by Editor\'s Core.'
  }
},
{
  type : 'paragraph',
  data : {
    text : `There are dozens of <a href="https://github.com/editor-js">ready-to-use Blocks</a> and the <a href="https://editorjs.io/creating-a-block-tool">simple API</a> for creation any Block you need. For example, you can implement Blocks for Tweets, Instagram posts, surveys and polls, CTA-buttons and even games.`
  }
},
{
  type: "header",
  data: {
    text: "What does it mean clean data output",
    level: 3
  }
}]