import { Box, Modal } from "@mui/material";
import React from "react";

/**
 * Displays a modal containing a preview of the file on top of the component
 */

 const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vw",
  height: "80vh",
  bgcolor: 'background.paper',
  boxShadow: 24,
};

export default function FilePreviewModal({ open, onClose, src }) {
  return (
    <Modal key={`modal-${src}`} open={open} onClose={onClose}>
      <Box key={`container-${src}`} sx={style}>
        <iframe key={`iframe-${src}`} width="100%" height="100%" src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(src)}`} title="File Preview" />
      </Box>
    </Modal>
  );
}
