import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import PropTypes from "prop-types";

export default function CheckboxQuestion({ question, answer, setAnswer }) {
  const { choices, index } = question;
  const handleChange = (event) => {
    setAnswer(index, { ...answer, [event.target.name]: event.target.checked });
  };

  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="multi-select">
        {choices.map((choice, choiceIndex) => (
          <FormControlLabel
            key={`q${index}_choice${choiceIndex}`}
            control={
              <Checkbox
                checked={answer === null ? false : answer[choice]}
                onChange={handleChange}
                name={choice}
              />
            }
            label={choice}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

CheckboxQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};
