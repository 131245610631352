import { Box, Card, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ErrorCard from "../components/ErrorCard";

export default function ErrorPage({ header, desc }) {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ErrorCard header={header} desc={desc} />
    </Box>
  );
}

ErrorPage.propTypes = {
  header: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};
