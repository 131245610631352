export const SET_USER_COURSE_DATA = "userCourseData/setUserCourseData";
export const UPDATE_USER_COURSE_DATA = "userCourseData/updateCourseData";
export const CLEAR_USER_COURSE_DATA = "userCourseData/clear";

export const setUserCourseDataAction = (userCourseData) => {
  return {
    type: SET_USER_COURSE_DATA,
    payload: userCourseData,
  };
};

export const updateUserCourseDataAction = (courseData) => {
  return {
    type: UPDATE_USER_COURSE_DATA,
    payload: courseData,
  };
};

export const clearUserCourseDataAction = () => {
  return {
    type: CLEAR_USER_COURSE_DATA,
  };
};
