import React, { Fragment } from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { ReactComponent as CheckedBox } from "../assets/icons/checked_box.svg";
import { ReactComponent as UncheckedBox } from "../assets/icons/unchecked_box.svg";
import { FOOTER_HEIGHT } from "./SavvyFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  setModuleMenuFocus,
  setSelectedIndex,
} from "../actions/moduleDataActions";
import {
  MENU_FOCUS_ASSIGNMENTS,
  MENU_FOCUS_CASE_STUDIES,
  MENU_FOCUS_KNOWLEDGE_CHECKS,
  MENU_FOCUS_MODULE,
} from "../constants/ModuleDataConstants";
import { styled } from "@mui/material/styles";

export default function ModuleDrawer({ moduleUserData }) {
  const dispatch = useDispatch();
  const title = useSelector((state) => state.moduleData.moduleTitle);
  const pages = useSelector((state) => state.moduleData.pages);
  const selectedIndex = useSelector((state) => state.moduleData.selectedIndex);
  const header = useSelector((state) => state.moduleData.moduleHeader);
  const menuFocus = useSelector((state) => state.moduleData.menuFocus);
  const primaryColor = useSelector((state) => state.moduleData.primaryColor);

  const ColoredTab = styled(Box)(({ theme }) => ({
    alignSelf: "stretch",
    backgroundColor: primaryColor || "#FF0000",
    width: "16px",
  }));

  const NavContent = () =>
    pages.map((page, index) => {
      // List Item for main ONLY
      const isPageEnabled = moduleUserData.pages[index].enabled;
      const isPageFinished = moduleUserData.pages[index].isFinished;

      const handleClick = () => {
        dispatch(setSelectedIndex(index));
        if (menuFocus !== MENU_FOCUS_MODULE) {
          // Only setMenuFocus to "Module" when we're not on the module
          dispatch(setModuleMenuFocus(MENU_FOCUS_MODULE));
        }
      };

      if (index === 0) {
        // Render Different ListItem for 1st El (MainElement)
        return (
          <Box key={`main-container-${index}`} sx={{ backgroundColor: "#FFF" }}>
            <ListItemButton
              key={`list-item-${index}`}
              disableGutters
              alignItems="flex-start"
              disabled={!isPageEnabled}
              sx={{ p: 0 }}
              onClick={handleClick}
            >
              <ColoredTab key={`coloredTab-${index}`} />
              <Box
                key={`box-content-${index}`}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  key={`header-${index}`}
                  sx={{ fontSize: 24, fontWeight: "bold", pt: 2, pl: 2, pr: 2 }}
                  color="#003057"
                  gutterBottom
                >
                  {header}
                </Typography>
                <Typography
                  key={`coloredTab-${index}`}
                  sx={{ fontSize: 18, px: 2 }}
                  color="#003057"
                  gutterBottom
                >
                  {title}
                </Typography>
              </Box>
            </ListItemButton>
            <Divider
              key={`main-divider-${index}`}
              sx={{ height: "4px", backgroundColor: "#003057" }}
            />
          </Box>
        );
      } else {
        // Render List item for everything else
        return (
          <Fragment key={`frag-${index}`}>
            {page.sectionHeader && (
              <Typography
                key={`section-header-${index}`}
                sx={{
                  fontSize: 18,
                  px: 2,
                  pt: 1,
                  backgroundColor: "#FFF",
                  fontWeight: "bold",
                }}
                color="#003057"
              >
                {page.sectionHeader}
              </Typography>
            )}
            <Box key={`container-${index}`} sx={{ backgroundColor: "#FFF" }}>
              <ListItemButton
                key={`list-item-${page.id}`}
                selected={
                  index === selectedIndex && menuFocus === "module"
                    ? true
                    : false
                }
                onClick={handleClick}
                disabled={!isPageEnabled}
                sx={{ display: "flex", py: 2 }}
              >
                {isPageFinished ? (
                  <CheckedBox
                    key={`check-${index}`}
                    style={{ width: 34, height: 30 }}
                  />
                ) : (
                  <UncheckedBox key={`uncheck-${index}`} />
                )}
                <ListItemText
                  key={`item-text-${index}`}
                  sx={{ px: 1, flex: "0 1 300px" }}
                >
                  {page.title}
                  <Divider
                    key={`divier-${index}`}
                    sx={{
                      mt: "4px",
                      height: "2px",
                      backgroundColor: "#003057",
                    }}
                  />
                </ListItemText>
              </ListItemButton>
            </Box>
          </Fragment>
        );
      }
    });

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#003057",
        },
        pb: 5,
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <List disablePadding sx={{ mb: `${FOOTER_HEIGHT}px` }}>
        {NavContent(pages)}
        <ListItemButton
          onClick={() =>
            dispatch(setModuleMenuFocus(MENU_FOCUS_KNOWLEDGE_CHECKS))
          }
        >
          <ListItemText
            disableTypography
            primary={
              <Typography
                sx={{ fontSize: 22, px: 1, fontWeight: "bold", color: "#FFF" }}
              >
                Knowledge Checks
              </Typography>
            }
          />
        </ListItemButton>
        <Divider sx={{ height: "4px", backgroundColor: "#FFF" }} />
        <ListItemButton
          onClick={() => dispatch(setModuleMenuFocus(MENU_FOCUS_ASSIGNMENTS))}
        >
          <ListItemText
            disableTypography
            primary={
              <Typography
                sx={{ fontSize: 22, px: 1, fontWeight: "bold", color: "#FFF" }}
              >
                Assignments/Templates
              </Typography>
            }
          />
        </ListItemButton>
        <Divider sx={{ height: "4px", backgroundColor: "#FFF" }} />
        <ListItemButton
          onClick={() => dispatch(setModuleMenuFocus(MENU_FOCUS_CASE_STUDIES))}
        >
          <ListItemText
            disableTypography
            primary={
              <Typography
                sx={{ fontSize: 22, px: 1, fontWeight: "bold", color: "#FFF" }}
              >
                Case Studies
              </Typography>
            }
          />
        </ListItemButton>
        <Divider sx={{ height: "4px", backgroundColor: "#FFF" }} />
      </List>
    </Drawer>
  );
}

const drawerWidth = 360;
/* 
const itemColors = [
  "linear-gradient(to bottom, #F78F21, #F7B618)",
  "linear-gradient(to bottom, #4C9D2F, #AECF61)",
  "linear-gradient(to bottom, #0067B0, #00BEF3)",
  "linear-gradient(to bottom, #61279E, #9264CD)",
  "linear-gradient(to bottom, #B4008E, #E678CC)",
]; 
*/
