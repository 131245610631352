import { Box, Button, Fade, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import PlaceHolderImage from "../../assets/images/placeholder_image.jpg";
import { useSelector } from "react-redux";
import ErrorCard from "../ErrorCard";

const PADDING_VIDEO = 4;

export default function VideoElement({ page, onEnd, fowardButton }) {
  const { uri, recap, transcript } = page.data;
  const courseData = useSelector((state) => state.userCourseData.courseData);
  const moduleId = useSelector((state) => state.moduleData.moduleId);
  const [toggleView, setToggleView] = useState(false); // False = Video, True = Recap
  const [isFinished, setIsFinished] = useState(false); // State to see if user finished video on mounted state
  const [loadedRecap, setLoadedRecap] = useState(false); // State if successfully loaded recap image
  const [errorRecap, setErrorRecap] = useState(false); // State if recap image had an error
  const [videoError, setVideoError] = useState(false); // State if video had an error
  const userData = courseData[moduleId].pages[page.index];
  const doesRecapExist = !!recap; // Could probably do a better check

  useEffect(() => {
    if(!uri) {
      onEnd();
    }
  }, [uri, videoError, onEnd])

  const handleFinishPlaying = () => {
    onEnd(); // set can proceed to true
    if (doesRecapExist) {
      setIsFinished(true);
      handleToggleClick(true);
    }
  };

  const handleLoadRecap = () => {
    setLoadedRecap(true);
  };

  const handleErrorRecap = () => {
    setErrorRecap(true);
  };

  const handleVideoError = () => {
    onEnd();
    setVideoError(true);
  }

  const handleDownloadTranscript = () => {
    window.open(transcript, "_blank");
  };

  const handleToggleClick = (value) => {
    if (toggleView === value) return; // Prevent re-render
    if (value) {
      // we want to set these states to the default value in case if we visited recap before
      setLoadedRecap(false);
      setErrorRecap(false);
    }
    setToggleView(value);
  };

  if (!uri) {
    return (
      <Fade in={true} appear={true}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            variant="h3"
            sx={{ mt: 5, mx: 5, fontWeight: "bold" }}
            color="primary"
            alignSelf="center"
            textAlign="center"
          >
            {page.title}
          </Typography>
          <Box
            sx={{
              alignSelf: "center",
              width: 50,
              height: 8,
              mt: 1,
              backgroundColor: "#FF0000",
            }}
          />
          <Typography
            sx={{ my: 2, mx: 5 }}
            variant="body1"
            color="#003057"
            textAlign="center"
            alignSelf="center"
          >
            {page.desc}
          </Typography>
          <ErrorCard
            header="Video Unavailable"
            desc="This video will be available at a later point in the future"
            rootStyle={{mb: 4}}
          />
          {fowardButton}
        </Box>
      </Fade>
    );
  }

  return (
    <Fade in={true} appear={true}>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        {doesRecapExist && (
          <Box display="flex" justifyContent="space-evenly" px={PADDING_VIDEO}>
            <Button
              variant="contained"
              color={!toggleView ? "secondary" : "primary"}
              sx={{ width: "100%", borderRadius: 0, borderTopLeftRadius: 10 }}
              onClick={() => handleToggleClick(false)}
            >
              Video
            </Button>
            <Button
              variant="contained"
              color={toggleView ? "secondary" : "primary"}
              sx={{ width: "100%", borderRadius: 0, borderTopRightRadius: 10 }}
              onClick={() => handleToggleClick(true)}
              disabled={!isFinished && !userData.isFinished}
            >
              Recap
            </Button>
          </Box>
        )}

        {/*Toggling between video and recap*/}
        {!toggleView || !doesRecapExist ? (
          <Fade key="fade-video" in={true} appear={true}>
            <Box
              component="video"
              key={uri}
              width="100%"
              px={PADDING_VIDEO}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              onContextMenu={(e) => e.preventDefault()}
              onEnded={handleFinishPlaying}
              onError={handleVideoError}
            >
              <source src={uri} type="video/mp4" />
            </Box>
          </Fade>
        ) : (
          <Fade key="fade-recap" in={loadedRecap}>
            <Box px={PADDING_VIDEO}>
              <Box
                component="img"
                src={errorRecap ? PlaceHolderImage : recap}
                width="100%"
                sx={{
                  aspectRatio: "16/9",
                  objectFit: "contain",
                  backgroundColor: "primary.main",
                }}
                onLoad={handleLoadRecap}
                onError={handleErrorRecap}
              />
            </Box>
          </Fade>
        )}

        <Typography
          variant="h3"
          sx={{ mt: 5, mx: 5, fontWeight: "bold" }}
          color="primary"
          alignSelf="center"
          textAlign="center"
        >
          {page.title}
        </Typography>
        <Box
          sx={{
            alignSelf: "center",
            width: 50,
            height: 8,
            mt: 1,
            backgroundColor: "#FF0000",
          }}
        />
        <Typography
          sx={{ my: 2, mx: 5 }}
          variant="body1"
          color="#003057"
          textAlign="center"
          alignSelf="center"
        >
          {page.desc}
        </Typography>
        <Button
          onClick={handleDownloadTranscript}
          variant="contained"
          color="primary"
          sx={{
            fontWeight: "bold",
            borderRadius: 5,
            minWidth: 100,
            mt: 1,
            mb: 4,
            py: 1,
            px: 2.5,
            alignSelf: "center",
          }}
        >
          <DownloadIcon sx={{ mr: 1 }} />
            Transcript
        </Button>
        {fowardButton}
      </Box>
    </Fade>
  );
}

VideoElement.propTypes = {
  page: PropTypes.object.isRequired,
  onEnd: PropTypes.func.isRequired,
};
