import { createContext, useEffect } from "react";
import { Route, Routes, useParams } from "react-router";
import DashboardPage from "../pages/DashboardPage";
import PropTypes from "prop-types";
import {
  createUserCourseData,
  getAllModules,
  getUserCourseDataSubscribe,
} from "../api/course";
import ModulePage from "../pages/ModulePage";
import { validateIfCourseIsRegistered } from "../util/validators";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUserCourseDataAction,
  setUserCourseDataAction,
} from "../actions/userCourseDataActions";
import {
  clearAppContextAction,
  setAppContextAction,
  setInitializationErrorAction,
  setIsInitialized,
  setModuleLoadingAction,
  setUserCourseDataLoadingAction,
} from "../actions/appContextActions";
import ErrorPage from "../pages/ErrorPage";
import LoadingPage from "../pages/LoadingPage";

export const AppContext = createContext({
  modules: null,
});

export default function AppRouter({ user }) {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const uid = user.credentials.uid;
  const registeredCourses = user.data.registeredCourses;

  const isCourseRegistered = validateIfCourseIsRegistered(
    courseId,
    registeredCourses
  );

  const isModuleLoading = useSelector(
    (state) => state.appContext.isModuleLoading
  );
  const isUserCourseDataLoading = useSelector(
    (state) => state.appContext.isUserCourseDataLoading
  );
  const isInitialized = useSelector((state) => state.appContext.isInitialized);
  const initializationError = useSelector(
    (state) => state.appContext.initializationError
  );

  // Cleanup
  useEffect(() => {
    return () => {
      dispatch(clearAppContextAction());
      dispatch(clearUserCourseDataAction());
    };
  }, [dispatch]);

  // Check if we initialized
  useEffect(() => {
    if (!isInitialized && !isModuleLoading && !isUserCourseDataLoading) {
      dispatch(setIsInitialized(true));
    }
  }, [dispatch, isInitialized, isModuleLoading, isUserCourseDataLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setModuleLoadingAction(true));
        const moduleData = await getAllModules(courseId);
        dispatch(
          setAppContextAction({ modules: moduleData, courseId: courseId })
        );
      } catch (error) {
        console.error(error);
        dispatch(setInitializationErrorAction(true));
      }

      // Finish Loading
      dispatch(setModuleLoadingAction(false));
    };

    if (isCourseRegistered) {
      fetchData();
    } else {
      dispatch(setInitializationErrorAction(true));
      dispatch(setModuleLoadingAction(false));
    }
  }, [courseId, dispatch, isCourseRegistered]);

  useEffect(() => {
    const unsubscribe = getUserCourseDataSubscribe(courseId, uid).onSnapshot(
      async (doc) => {
        dispatch(setUserCourseDataLoadingAction(true));
        if (!isCourseRegistered) {
          dispatch(setUserCourseDataLoadingAction(false));
          return;
        }

        if (doc.exists) {
          dispatch(setUserCourseDataAction(doc.data()));
        } else {
          const initialUserCourseData = {
            createdAt: new Date(),
            courseData: {},
          };
          await createUserCourseData(courseId, uid, initialUserCourseData);
          dispatch(setUserCourseDataAction(initialUserCourseData));
        }

        dispatch(setUserCourseDataLoadingAction(false));
      },
      (error) => {
        console.error(error);
        dispatch(setInitializationErrorAction(true));
        dispatch(setUserCourseDataLoadingAction(false));
      }
    );

    return () => {
      unsubscribe();
    };
  }, [courseId, uid, dispatch, isCourseRegistered]);

  // Conditional Rendering
  if ((isModuleLoading || isUserCourseDataLoading) && !isInitialized) {
    return <LoadingPage />;
  }

  // Done Loading
  if (isInitialized && !initializationError) {
    return (
      <Routes>
        <Route exact path="/" element={<DashboardPage user={user} />} />
        <Route
          exact
          path="/module/:moduleId"
          element={<ModulePage user={user} />}
        />
      </Routes>
    );
  } else {
    const header = "Error Loading Course";
    const desc =
      "It looks like the course isn't available at the moment. Please try again later";
    return <ErrorPage header={header} desc={desc} />;
  }
}

AppRouter.propTypes = {
  user: PropTypes.object.isRequired,
};
