import React, { useEffect, useState } from "react";
import { Box, Container, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getCourseList } from "../api/course";
import CourseCard from "../components/CourseCard";
import MainAppBar from "../components/MainAppBar";
import BannerLogoCard from "../components/BannerLogoCard";
import Banner from "../assets/images/courses_banner.jpg";
import BookIcon from "../assets/icons/ic_book.svg";
import SavvyFooter from "../components/SavvyFooter";
import LoadingPage from "./LoadingPage";

export default function CoursesPage({ user }) {
  const navigate = useNavigate();
  const [data, setData] = useState({ courses: null, loading: true });

  const fetchData = async (registeredCourses) => {
    try {
      const courses = await getCourseList(registeredCourses);
      setData({ courses: courses, loading: false });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // User validation
    if (
      (user.credentials === null || user.data === null) &&
      data.loading !== false
    ) {
      return;
    }

    // Check if registeredCourses is defined so we can fetchData without issues
    if (typeof user.data.registeredCourses != "undefined") {
      // Handle if user doesn't have any registered courses
      if (user.data.registeredCourses.length === 0) {
        setData({ courses: null, loading: false });
      } else {
        // User passes all checks; get registered courses
        try {
          fetchData(user.data.registeredCourses);
        } catch (error) {
          setData({ courses: null, loading: false });
          console.error(error);
        }
      }
    } else {
      // registeredCourses wasn't defined, problem with initialization shouldn't happen! Should prob show error page
      setData({ courses: null, loading: false });
      console.error("User Error: Not Initialized Properly");
    }
  }, [data.loading, user]);

  const handleProjectClick = (courseId) => {
    navigate(`/app/${courseId}/`);
  };

  return data.loading ? (
    <LoadingPage />
  ) : (
    <Box sx={{ display: "flex" }}>
      <MainAppBar />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          overflow: "auto",
          backgroundColor: "#EAF8FD",
        }}
      >
        <Toolbar />
        <Container
          disableGutters
          maxWidth="lg"
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFF",
          }}
        >
          <Box sx={{ alignSelf: "stretch" }}>
            <BannerLogoCard imageUri={Banner} />
          </Box>

          <Box
            sx={{ mt: 4 }}
            component="img"
            src={BookIcon}
            height={64}
            alt="Book Icon"
          />
          <Typography sx={{mt: 1}} fontSize={42} fontWeight="bold">
            My Courses
          </Typography>

          <Box sx={{ mt: 4, alignSelf: "stretch", backgroundColor: "#b8bbb8" }}>
            {data.courses === null
              ? null
              : data.courses.map((course) => (
                  <CourseCard
                    imageUri={course.image}
                    course={course}
                    key={course.id}
                    onClick={() => handleProjectClick(course.id)}
                  />
                ))}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
