import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Fade,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const bannerHeight = 300;

const FiCardMedia = styled(CardMedia)(({ theme }) => ({
  height: "100%",
  width: "100%",
  objectFit: "cover",
  filter: "brightness(70%)"
}));

const FiCardContent = styled(CardContent)(({ theme }) => ({
  position: "relative",
  height: "100%",
  backgroundColor: "transparent",
}));

export default function BannerCard({ image, title, subtitle }) {
  const [ready, setReady] = useState(false);
  const handleLoad = () => {
    setReady(true);
  };

  return (
    <Card
      sx={{
        position: "relative",
        boxShadow: 0,
        height: bannerHeight,
        alignSelf: "stretch",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "primary.main",
        }}
        width="100%"
        height="100%"
      >
        <Fade in={ready}>
          <FiCardMedia image={image} onLoad={handleLoad} component="img" />
        </Fade>
      </Box>

      <FiCardContent
        sx={{ color: "#ffffff", backgroundColor: "rgba(0,0,0,.24)" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            textAlign="center"
            fontWeight="bold"
            sx={{ mt: 5 }}
          >
            {title}
          </Typography>
          <Typography variant="h4" textAlign="center" component="h4">
            {subtitle}
          </Typography>
          <Box
            sx={{
              mt: 1,
              backgroundColor: "#FFF",
              width: 60,
              height: 8,
              borderRadius: 1,
            }}
          />
        </Box>
      </FiCardContent>
    </Card>
  );
}
