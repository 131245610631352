import React, { createContext, useEffect } from "react";
import { Box, Container, Toolbar } from "@mui/material";
import MainAppBar from "../components/MainAppBar";
import ModuleDrawer from "../components/ModuleDrawer";
import { useLocation, useParams } from "react-router";
import RenderPageElement from "../components/RenderPageElement";
import { getEditable } from "../util/pageHelper";
import { useSelector } from "react-redux";
import KnowledgeChecks from "../components/PageElements/KnowledgeChecks";
import AssignmentElement from "../components/PageElements/AssignmentElement";
import { useDispatch } from "react-redux";
import SavvyFooter from "../components/SavvyFooter";
import {
  clearModuleData,
  initializeModuleData,
  setEditable,
  setModuleDataError,
  setModuleDataLoading,
  setUserModuleDataLoading,
} from "../actions/moduleDataActions";
import ErrorPage from "./ErrorPage";
import LoadingPage from "./LoadingPage";
import {
  getPagesList,
  updateUserCourseData,
  getAssignments,
} from "../api/course";
import {
  MENU_FOCUS_ASSIGNMENTS,
  MENU_FOCUS_CASE_STUDIES,
  MENU_FOCUS_KNOWLEDGE_CHECKS,
  MENU_FOCUS_MODULE,
} from "../constants/ModuleDataConstants";
import { CSSTransition } from "react-transition-group";
import "../styles/CSSTransition.css";
import CaseStudiesElement from "../components/PageElements/CaseStudiesElement";

export const ModuleContext = createContext(null);

export default function ModulePage({ user }) {
  const { courseId, moduleId } = useParams();
  const modules = useSelector((state) => state.appContext.modules);
  const courseData = useSelector((state) => state.userCourseData.courseData);
  const module = useSelector((state) => state.moduleData);
  const uid = user.credentials.uid;
  //const { search } = useLocation();
  const dispatch = useDispatch();

  //const editable = getEditable(search, user.data.role) && !module.isReady;

  useEffect(() => {
    return () => {
      dispatch(clearModuleData());
    };
  }, [dispatch]);

  /**
   * UseEffect to initialize moduleData needed (module's pages, user module data, setting editable)
   */
  useEffect(() => {
    const fetchData = async () => {
      const initalModuleData = modules.find((module) => module.id === moduleId);
      if (!initalModuleData) {
        dispatch(setModuleDataError(true));
        dispatch(setModuleDataLoading(false));
        return;
      }

      try {
        const pages = await getPagesList(courseId, moduleId);
        const assignments = await getAssignments(courseId, moduleId);
        console.log(module.isModuleDataLoading);
        dispatch(
          initializeModuleData({
            ...initalModuleData,
            pages: pages,
            assignments: assignments,
          })
        );
      } catch (error) {
        console.error(error);
        dispatch(setModuleDataError(true));
      } finally {
        dispatch(setModuleDataLoading(false));
      }
    };

    if (module.isModuleDataLoading && !module.error) fetchData();
  }, [
    courseId,
    dispatch,
    module.error,
    module.isModuleDataLoading,
    moduleId,
    modules,
  ]);

  /** UseEffect to initialize user data if a user has not made a course yet */
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!courseData[moduleId]) {
          const newCourseData = initialModuleUserData(
            moduleId,
            module.pages, // Pages will be initialized by now, see other useEffect
            courseData
          );
          await updateUserCourseData(uid, courseId, newCourseData);
        }
      } catch (error) {
        console.error(error);
        dispatch(setModuleDataError(true));
      } finally {
        dispatch(setUserModuleDataLoading(false));
      }
    };

    /** Check if moduleData isn't loading and there isn't any error. And moduleUserData is loading */
    if (!module.isModuleDataLoading && module.isUserDataLoading) {
      if (module.error) {
        /** An error has occured getting moduleData so we can't set userData */
        dispatch(setUserModuleDataLoading(false));
        return;
      }
      fetchUserData();
    }
  }, [
    courseData,
    courseId,
    dispatch,
    module.error,
    module.isModuleDataLoading,
    module.isUserDataLoading,
    module.pages,
    moduleId,
    uid,
  ]);

  if (module.isModuleDataLoading || module.isUserDataLoading) {
    return <LoadingPage />;
  }

  if (!courseData[moduleId] || module.error) {
    const header = "Course Data Not Found";
    const desc = "Please try again later";
    return <ErrorPage header={header} desc={desc} />;
  }

  const renderPage = () => {
    switch (module.menuFocus) {
      case MENU_FOCUS_MODULE:
        return (
          <CSSTransition
            appear={true}
            in={true}
            timeout={1000}
            classNames="fade"
          >
            <RenderPageElement uid={uid} />
          </CSSTransition>
        );
      case MENU_FOCUS_KNOWLEDGE_CHECKS:
        return <KnowledgeChecks uid={uid} />;
      case MENU_FOCUS_ASSIGNMENTS:
        return <AssignmentElement />;
      case MENU_FOCUS_CASE_STUDIES:
        return <CaseStudiesElement />;
      default:
        return <h1>No Page Exists</h1>;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MainAppBar />
      <ModuleDrawer moduleUserData={courseData[moduleId]} />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          backgroundColor: "#EAF8FD",
        }}
      >
        <Toolbar />
        <Container
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            backgroundColor: "#FFF",
          }}
          maxWidth="lg"
        >
          {renderPage()}
        </Container>
      </Box>
    </Box>
  );
}

function initialModuleUserData(moduleId, pages, courseData) {
  let newCourseData = {
    ...courseData,
  };

  newCourseData[moduleId] = {
    isComplete: false,
    pages: [
      ...Array(pages.length)
        .fill(0)
        .map((x, i) => {
          if (pages[i].type === "quiz") {
            return {
              retryCount: 0,
              enabled: false,
              quizData: null,
              score: null,
            };
          } else {
            return { isFinished: false, enabled: i === 0 ? true : false };
          }
        }),
    ],
  };

  return newCourseData;
}
