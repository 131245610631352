import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RadioQuestion from "../../components/QuestionTypes/RadioQuestion";
import CheckboxQuestion from "../../components/QuestionTypes/CheckboxQuestion";
import OpenEndedQuestion from "../../components/QuestionTypes/OpenEndedQuestion";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { updateUserCourseData } from "../../api/course";
import { cloneDeep } from "lodash";
import QuizBanner from "../../assets/images/quiz_banner.svg";

const buttonWidth = 100;

function findIsOnLastQuestion(index, length) {
  return index === length - 1 && length > 1 ? true : false;
}

function findIsOnFirstQuestion(index, length) {
  return index === 0 || length === 1 ? true : false;
}

function calculateScore(questions, answers) {
  let correct = 0;
  let incorrect = 0;
  for (let i = 0; i < questions.length; i++) {
    if (isEqual(answers[i], questions[i].answer)) {
      correct++;
    } else {
      incorrect++;
    }
  }
  return { correct, incorrect, total: correct + incorrect };
}

function decideQuestionRender(question, answer, answerCallback) {
  switch (question.type) {
    case "radio":
      return (
        <RadioQuestion
          question={question}
          answer={answer}
          setAnswer={answerCallback}
        />
      );
    case "multiselect":
      return (
        <CheckboxQuestion
          question={question}
          answer={answer}
          setAnswer={answerCallback}
        />
      );
    case "essay":
      return (
        <OpenEndedQuestion
          question={question}
          answer={answer}
          setAnswer={answerCallback}
        />
      );
    default:
      return <p>Question Type Doesn't Exist</p>;
  }
}

function initializeAnswerState(quiz) {
  let initialState = [];
  for (let i = 0; i < quiz.questions.length; i++) {
    const question = quiz.questions[i];
    if (question.type === "radio") {
      initialState.push("");
    } else if (question.type === "multiselect") {
      initialState.push(
        Object.assign(
          {},
          ...question.choices.map((choice) => ({ [choice]: false }))
        )
      );
    } else if (question.type === "essay") {
      initialState.push("");
    } else {
      initialState.push(null);
    }
  }

  return initialState;
}

export default function QuizElement({
  uid,
  page,
  setCanProceed,
  fowardButton,
}) {
  const courseId = useSelector((state) => state.appContext.courseId);
  const moduleId = useSelector((state) => state.moduleData.moduleId);
  const courseData = useSelector((state) => state.userCourseData.courseData);
  const userQuizData = courseData[moduleId].pages[page.index];

  const { quiz } = page.data;
  const { title } = page;

  const [started, setStarted] = useState(false);
  const [answers, setAnswers] = useState(initializeAnswerState(quiz)); // Track Answers
  const [index, setIndex] = useState(0); // Track Current Question Index

  let question = cloneDeep(quiz.questions[index]);
  question.index = index;

  const canProceedToNextQuestion = answers[index] ? true : false;
  const isOnLastQuestion = findIsOnLastQuestion(index, quiz.questions.length);
  console.log(userQuizData);
  useEffect(() => {
    setCanProceed(true);
    /**
    if (userQuizData.score) {
      if (!(userQuizData.score.incorrect >= quiz.incorrectCount)) {
        setCanProceed(true);
      }
    }
    */
  }, [quiz.incorrectCount, setCanProceed, userQuizData.score]);

  const setAnswer = (index, answer) => {
    setAnswers((prevState) => {
      let newState = [...prevState];
      newState[index] = answer;
      return newState;
    });
  };

  const questionNumber = (index + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const handleBegin = () => {
    setStarted(true);
  };

  const handleNext = () => {
    if (canProceedToNextQuestion) {
      setIndex(index + 1);
    }
  };

  const handleSubmit = async () => {
    // Set the data in user data
    if (canProceedToNextQuestion) {
      let newCourseData = cloneDeep(courseData);
      newCourseData[moduleId].pages[page.index] = {
        ...newCourseData[moduleId].pages[page.index],
        quizData: answers,
        score: calculateScore(quiz.questions, answers),
      };

      try {
        await updateUserCourseData(uid, courseId, newCourseData);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleRetry = async () => {
    let newCourseData = cloneDeep(courseData);
    newCourseData[moduleId].pages[page.index] = {
      ...newCourseData[moduleId].pages[page.index],
      quizData: null,
      score: null,
      retryCount: newCourseData[moduleId].pages[page.index].retryCount + 1,
    };

    try {
      await updateUserCourseData(uid, courseId, newCourseData);
      setStarted(false);
      setAnswers(initializeAnswerState(quiz));
      setIndex(0);
    } catch (error) {
      console.error(error);
    }
  };

  // Start Coniditional Rendering
  if (quiz.questions.length === 0) return;
  else if (
    // Handle User didn't start quiz yet
    userQuizData.quizData === null &&
    userQuizData.score === null &&
    started === false
  ) {
    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          component={"img"}
          alignSelf="stretch"
          src={QuizBanner}
          alt="Quiz Banner"
        />
        <Typography
          m={4}
          variant="h3"
          sx={{ fontWeight: "bold" }}
          color="primary"
          textAlign="center"
        >
          {title}
        </Typography>

        <Typography fontSize={18} color="primary" textAlign="center">
          This knowledge check contains up to {quiz.numOfQuestions} questions.
        </Typography>
        <Typography fontSize={18} color="primary" textAlign="center">
          You may get up to 4 wrong.
        </Typography>
        <Typography fontSize={18} color="primary" textAlign="center">
          You may retake it as many times as you'll need.
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleBegin}
          sx={{
            fontWeight: "bold",
            borderRadius: 5,
            minWidth: 100,
            py: 1,
            px: 4,
            mt: 4,
          }}
        >
          Begin
        </Button>

        <Typography sx={{mt: 4}}>Skip Quiz</Typography>
        {fowardButton}
      </Box>
    );
  } else if (userQuizData.score && userQuizData.quizData) {
    // Handle user finished quiz and has a score
    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          component={"img"}
          alignSelf="stretch"
          src={QuizBanner}
          alt="Quiz Banner"
        />
        <Typography
          m={4}
          variant="h3"
          sx={{ fontWeight: "bold" }}
          color="primary"
        >
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: 32, mt: 5, fontWeight: "bold" }}
          color="primary"
          align="center"
        >
          You got {userQuizData.score.correct} out of {userQuizData.score.total}
        </Typography>
        <Typography
          sx={{ fontSize: 32, fontWeight: "bold" }}
          color="primary"
          align="center"
        >
          correct
        </Typography>
        {userQuizData.score.incorrect >= 3 ? (
          <>
            <Typography
              sx={{ fontSize: 24, mt: 5, fontWeight: "bold" }}
              color="primary"
            >
              You may only get up to 3 wrong
            </Typography>
            <Button
              variant="contained"
              sx={{ width: buttonWidth, backgroundColor: "#F0A324", my: 2 }}
              onClick={handleRetry}
            >
              Retry
            </Button>
          </>
        ) : (
          <>
            <Typography
              sx={{ fontSize: 34, mt: 5, fontWeight: "bold" }}
              color="primary"
            >
              You Passed!
            </Typography>
            {fowardButton}
          </>
        )}
      </Box>
    );
  } else {
    // Else Render Question
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component={"img"}
          alignSelf="stretch"
          src={QuizBanner}
          alt="Quiz Banner"
        />
        <Typography
          mx={4}
          mt={2}
          variant="h3"
          fontWeight="bold"
          color="primary"
          textAlign="center"
        >
          {quiz.title}
        </Typography>
        <Typography variant="h4" fontWeight="bold" color="secondary">
          {questionNumber}
        </Typography>
        <Typography
          variant="h5"
          mx={4}
          mb={2}
          fontWeight="bold"
          color="secondary"
          textAlign="center"
          maxWidth={800}
        >
          {question.content}
        </Typography>
        {decideQuestionRender(question, answers[index], setAnswer)}

        <Box sx={{ my: 4 }}>
          {isOnLastQuestion ? (
            <Button
              variant="contained"
              sx={{ width: buttonWidth, backgroundColor: "#F0A324" }}
              disabled={!canProceedToNextQuestion}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ width: buttonWidth, backgroundColor: "#F0A324", mx: 2 }}
              disabled={!canProceedToNextQuestion}
              onClick={handleNext}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
    );
  }
}
