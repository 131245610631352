import React, { useState } from "react";
import { Box, Fade, Typography } from "@mui/material";
import ErrorCard from "../ErrorCard";

export default function ImageElement({ page, fowardButton }) {
  const { uri } = page.data;
  const { title, desc } = page;
  const [fadeIn, setFadeIn] = useState(false);
  const [error, setError] = useState(false);

  /**
   * On Successful Load Image
   */
  const handleLoad = () => {
    setFadeIn(true);
  };

  /**
   * On Failed Load Image
   */
  const handleError = () => {
    setError(true);
    setFadeIn(true);
  };


  if (error || !uri) {
    return (
      <Fade in={fadeIn || !uri}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography
            variant="h3"
            sx={{ mt: 5, mx: 5, fontWeight: "bold" }}
            color="primary"
            alignSelf="center"
            textAlign="center"
          >
            {title}
          </Typography>
          <Typography
            sx={{ my: 2, mx: 5 }}
            variant="body1"
            color="#003057"
            textAlign="center"
            alignSelf="center"
          >
            {desc}
          </Typography>
          <ErrorCard header="Error Loading Content" desc="Please try again later or if the issue presists, contact the IT department."/>
          <Box mt={2}/>
          {fowardButton}
        </Box>
      </Fade>
    );
  }

  return (
    <Fade in={fadeIn}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="h3"
          sx={{ mt: 5, mx: 5, fontWeight: "bold" }}
          color="primary"
          alignSelf="center"
          textAlign="center"
        >
          {title}
        </Typography>
        <Typography
          sx={{ my: 2, mx: 5 }}
          variant="body1"
          color="#003057"
          textAlign="center"
          alignSelf="center"
        >
          {desc}
        </Typography>
        <Box
          component="img"
          src={uri}
          alignSelf="stretch"
          p={4}
          onLoad={handleLoad}
          onError={handleError}
        />
        {fowardButton}
      </Box>
    </Fade>
  );
}
