import { Box, Button, Modal, Typography } from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 640,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: 4,
};

export default function PostModuleModal({ open, handleClose, courseId, moduleInfo }) {
  const title = useSelector((state) => state.moduleData.moduletitle);
  const header = useSelector((state) => state.moduleData.moduleHeader);
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="post-module-modal-modal-title"
      aria-describedby="post-module-modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CelebrationIcon fontSize="large" sx={{ mr: 2 }} />
          <Typography
            id="post-module-modal-modal-title"
            variant="h4"
            sx={{ fontWeight: "bold" }}
            component="h4"
          >
            Congratulations!
          </Typography>
        </Box>
        <Typography
          textAlign={"center"}
          fontSize={18}
          id="post-module-modal-modal-description"
          sx={{ mt: 2 }}
        >
          You have completed Module {header}
        </Typography>
        <Typography
          textAlign={"center"}
          fontSize={18}
        >
          {title}.
        </Typography>
        <Typography
          textAlign={"center"}
          fontSize={18}
          sx={{mt: 3}}
        >
          Press the button below to the dashboard
        </Typography>
        <Button sx={{mt: 1}} variant="outlined" onClick={() => navigate(`/app/${courseId}`)}>Dashboard</Button>
      </Box>
    </Modal>
  );
}
