export const SET_APP_CONTEXT = "appContext/setAppContext";
export const CLEAR_APP_CONTEXT = "appContext/clear";
export const SET_MODULE_LOADING = "appContext/setModuleLoading";
export const SET_INITIALIZATION_ERROR = "appContext/setInitializationError";
export const SET_USER_COURSE_DATA_LOADING = "appContext/setUserCourseDataLoading";
export const SET_IS_INITIALIZED = "appContext/setIsInitialized"

export const setAppContextAction = (context) => {
  return {
    type: SET_APP_CONTEXT,
    payload: context,
  };
};

export const clearAppContextAction = () => {
  return {
    type: CLEAR_APP_CONTEXT,
  };
};

export const setModuleLoadingAction = (isModuleLoading) => {
  return {
    type: SET_MODULE_LOADING,
    payload: isModuleLoading,
  }
}

export const setUserCourseDataLoadingAction = (isUserCourseDataLoading) => {
  return {
    type: SET_USER_COURSE_DATA_LOADING,
    payload: isUserCourseDataLoading,
  }
}

export const setIsInitialized = (isInitialized) => {
  return {
    type: SET_IS_INITIALIZED,
    payload: isInitialized,
  }
}

export const setInitializationErrorAction = (isError) => {
  return {
    type: SET_INITIALIZATION_ERROR,
    payload: isError,
  }
}