import { db, firestore } from "./firebase";

export const DATA_NOT_FOUND_EXCEPTION = "DataNotFoundException";

function DataNotFoundException(message) {
  this.message = message;
  this.name = DATA_NOT_FOUND_EXCEPTION;
}

DataNotFoundException.prototype.toString = function () {
  return `${this.name}: "${this.message}"`;
};

export const getCourseList = async (registedCourses) => {
  try {
    const coursesDoc = await db
      .collection("courses")
      .where(firestore.FieldPath.documentId(), "in", registedCourses)
      .get();

    let courses = [];
    coursesDoc.forEach((course) => {
      courses.push({
        ...course.data(),
        id: course.id,
      });
    });

    return courses;
  } catch (error) {
    throw error;
  }
};

export const getModule = async (courseId, moduleId) => {
  const moduleRef = db
    .collection("courses")
    .doc(courseId)
    .collection("modules")
    .doc(moduleId);

  try {
    let doc = await moduleRef.get();

    return doc.data();
  } catch (error) {
    throw error;
  }
};

export const getAllModules = async (courseId) => {
  const modulesRef = db
    .collection("courses")
    .doc(courseId)
    .collection("modules")
    .orderBy("index");

  try {
    let allModuleSnapshot = await modulesRef.get();
    let modules = [];

    // Check if we didn't grab any modules
    if (allModuleSnapshot.size === 0) {
      throw new DataNotFoundException(
        "No module data found. Course doesn't exist or no modules exist in this course"
      );
    }

    allModuleSnapshot.forEach((doc) => {
      modules.push({ ...doc.data(), id: doc.id });
    });

    console.log(modules);

    return modules;
  } catch (error) {
    throw error;
  }
};

export const getPagesList = async (courseId, moduleId) => {
  const pagesRef = db
    .collection("courses")
    .doc(courseId)
    .collection("modules")
    .doc(moduleId)
    .collection("pages")
    .orderBy("index");

  try {
    let allPageSnapshot = await pagesRef.get();
    let pages = [];

    allPageSnapshot.forEach((doc) => {
      pages.push({ id: doc.id, ...doc.data() });
    });

    return pages;
  } catch (error) {
    throw error;
  }
};

export const getAssignments = async (courseId, moduleId) => {
  const pagesRef = db
  .collection("courses")
  .doc(courseId)
  .collection("modules")
  .doc(moduleId)
  .collection("assignments")
  .orderBy("index");

  try {
    let allAssignmentsSnapshot = await pagesRef.get();
    let assignments = [];

    allAssignmentsSnapshot.forEach((doc) => {
      assignments.push({ id: doc.id, ...doc.data() });
    });

    return assignments;
  } catch(error) {
    throw error;
  }
}

export const createUserCourseData = async (courseId, userId, values) => {
  const userRef = db
    .collection("courses")
    .doc(courseId)
    .collection("users")
    .doc(userId);

  try {
    await userRef.set({
      ...values,
    });
  } catch (error) {
    throw error;
  }
};

export const getUserCourseDataSubscribe = (courseId, userId) => {
  return db.collection("courses").doc(courseId).collection("users").doc(userId);
};

export const getUserCourseData = async (courseId, userId) => {
  const userDataRef = db
    .collection("courses")
    .doc(courseId)
    .collection("users")
    .doc(userId);

  try {
    let doc = await userDataRef.get();
    return doc;
  } catch (error) {
    throw error;
  }
};

export const updatePageData = async (courseId, moduleId, pageId, data) => {
  const pageDataRef = db
    .collection("courses")
    .doc(courseId)
    .collection("modules")
    .doc(moduleId)
    .collection("pages")
    .doc(pageId);

  try {
    await pageDataRef.update({
      data: data,
    });
  } catch (error) {
    throw error;
  }
};

export const updateUserCourseData = async (userId, courseId, courseData) => {
  const userDataRef = db
    .collection("courses")
    .doc(courseId)
    .collection("users")
    .doc(userId);

  try {
    await userDataRef.update({
      courseData: courseData,
    });
  } catch (error) {
    throw error;
  }
};
