import {
  CLEAR_MODULE_DATA,
  INITIALIZE_MODULE_DATA,
  SET_IS_PREVIEW,
  SET_MODULE_DATA_ERROR,
  SET_MODULE_DATA_LOADING,
  SET_MODULE_MENU_FOCUS,
  SET_SELECTED_INDEX,
  SET_USER_MODULE_DATA_LOADING,
} from "../actions/moduleDataActions";

const initialState = {
  moduleId: null,
  moduleTitle: "",
  moduleDesc: "",
  moduleIndex: null,
  moduleHeader: "",
  moduleImage: "",
  primaryColor: "",
  menuFocus: "module",
  pages: [],
  assignments: [],
  isEditable: false,
  isPreview: false,
  selectedIndex: 0,
  isModuleDataLoading: true,
  isUserDataLoading: true,
  error: false,
};

export default function moduleDataReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_MODULE_DATA:
      return {
        ...state,
        moduleId: action.payload.id,
        moduleTitle: action.payload.title,
        moduleDesc: action.payload.desc,
        moduleIndex: action.payload.index,
        moduleHeader: action.payload.header,
        moduleImage: action.payload.image,
        pages: action.payload.pages,
        assignments: action.payload.assignments,
        primaryColor: action.payload.primaryColor,
      };
    case SET_SELECTED_INDEX:
      return {
        ...state,
        selectedIndex: action.payload,
      };
    case SET_IS_PREVIEW:
      return {
        ...state,
        isPreview: action.payload,
      };
    case CLEAR_MODULE_DATA:
      return initialState;
    case SET_MODULE_DATA_LOADING:
      return {
        ...state,
        isModuleDataLoading: action.payload,
      };
    case SET_USER_MODULE_DATA_LOADING:
      return {
        ...state,
        isUserDataLoading: action.payload,
      };
    case SET_MODULE_MENU_FOCUS:
      return {
        ...state,
        menuFocus: action.payload,
      };
    case SET_MODULE_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
