import { Button, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setModuleMenuFocus,
  setSelectedIndex,
} from "../../actions/moduleDataActions";
import QuizBanner from "../../assets/images/quiz_banner.svg";
import { MENU_FOCUS_MODULE } from "../../constants/ModuleDataConstants";

export default function KnowledgeChecks() {
  const pages = useSelector((state) => state.moduleData.pages);
  const courseData = useSelector((state) => state.userCourseData.courseData);
  const moduleId = useSelector((state) => state.moduleData.moduleId);
  const quizList = pages.filter((page) => page.type === "quiz");
  const dispatch = useDispatch();

  const handleClick = (index) => {
    dispatch(setSelectedIndex(index));
    dispatch(setModuleMenuFocus(MENU_FOCUS_MODULE));
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        component={"img"}
        alignSelf="stretch"
        src={QuizBanner}
        alt="Quiz Banner"
      />
      <Typography
        variant="h3"
        sx={{ mt: 2, fontWeight: "bold" }}
        color="primary"
        align="center"
      >
        Knowledge Checks
      </Typography>

      {quizList.map((page) => {
        const { enabled, isFinished, quizData, score } =
          courseData[moduleId].pages[page.index];
        const { title } = page;
        const { incorrectCount } = page.data.quiz;

        let status = "";
        if (isFinished) {
          status = "Completed";
        } else if (quizData === null && score === null && !isFinished) {
          status = "Not Finished";
        } else if (!enabled) {
          status = "Quiz not unlocked";
        } else if (quizData && score && !isFinished) {
          if (score.incorrect >= incorrectCount) {
            status = "Must retake";
          } else {
            status = "Passed";
          }
        }

        return (
          <Box width="100%" my={4} px={5} maxWidth={800}>
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>
            <Typography fontSize={16} fontWeight="bold">
              {status}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              disabled={!enabled}
              onClick={() => handleClick(page.index)}
              sx={{
                borderRadius: 5,
                fontWeight: "bold",
                textTransform: "none",
                mt: 1,
              }}
            >
              Go to Quiz
            </Button>
            <Divider
              sx={{ my: 2, borderBottomWidth: 2, borderColor: "primary.main" }}
            />
          </Box>
        );
      })}
    </Box>
  );
}
