import React from "react";
import { Card, Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function ErrorCard({ header, desc, rootStyle }) {
  return (
    <Card sx={{ p: 4, ...rootStyle}}>
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ fontWeight: "bold", mb: 1 }}
      >
        {header}
      </Typography>
      <Typography textAlign="center" variant="body1">{desc}</Typography>
    </Card>
  );
}

ErrorCard.propTypes = {
  /** Header/title of the error */
  header: PropTypes.string.isRequired,
  /** Description of the error */
  desc: PropTypes.string.isRequired,
  /** Style applied to the root element "Card" */
  rootStyle: PropTypes.object,
};
