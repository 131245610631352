import {
  CLEAR_USER_COURSE_DATA,
  SET_USER_COURSE_DATA,
  UPDATE_USER_COURSE_DATA,
} from "../actions/userCourseDataActions";

const initialState = {
  courseData: null,
  createdAt: null,
};

export default function userCourseDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_COURSE_DATA:
      return {
        ...state,
        courseData: action.payload.courseData,
        createdAt: action.payload.createdAt,
      };
    case UPDATE_USER_COURSE_DATA:
      return {
        ...state,
        courseData: action.payload,
      };
    case CLEAR_USER_COURSE_DATA:
      return initialState;
    default:
      return state;
  }
}
