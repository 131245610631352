import {
  Button,
  Grid,
  TextField,
  Typography,
  Link,
  Alert,
  Box,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import { signInUser } from "../api/user";
import BackgroundImage from "../assets/images/lms_marketingway_image.jpg";
import MerckLogo from "../assets/icons/merck_logo.svg";
import PoweredBySavvyLogo from "../assets/icons/powered_by_savvy.svg";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signInUser(email, password);
    } catch (error) {
      console.log(error);
      setErrors(error);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        sx={{ background: "linear-gradient(#0067B0, #00C0F3);" }}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={MerckLogo}
            style={{ marginBottom: 24 }}
            alt="Marketing Way"
            height="92px"
          />
          <Typography
            component="h1"
            variant="h1"
            color="white"
            sx={{ fontWeight: "bold" }}
          >
            Sign in
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              error={errors.email ? true : false}
              helperText={errors.email}
              onChange={(event) => setEmail(event.target.value)}
              sx={{ backgroundColor: "white" }}
              autoFocus
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={errors.password ? true : false}
              helperText={errors.password}
              onChange={(event) => setPassword(event.target.value)}
              sx={{ backgroundColor: "white" }}
            />

            {errors.invalid ? (
              <Alert style={{ margin: "5px 0px 5px 0px" }} severity="error">
                {errors.invalid}
              </Alert>
            ) : null}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2, color: "#FFF" }}
              onClick={handleSubmit}
            >
              Sign In
            </Button>

            <Grid container>
              <Grid item xs>
                <Link color="#FFF" href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link color="#FFF" href="/signUp" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
