import {
  CLEAR_APP_CONTEXT,
  SET_APP_CONTEXT,
  SET_INITIALIZATION_ERROR,
  SET_IS_INITIALIZED,
  SET_MODULE_LOADING,
  SET_USER_COURSE_DATA_LOADING,
} from "../actions/appContextActions";

const initialState = {
  courseId: null,
  modules: [],
  isModuleLoading: true,
  isUserCourseDataLoading: true,
  initializationError: false,
  isInitialized: false,
};

export default function appContextReducer(state = initialState, action) {
  switch (action.type) {
    case SET_APP_CONTEXT:
      return {
        ...state,
        courseId: action.payload.courseId,
        modules: action.payload.modules,
      };
    case SET_MODULE_LOADING:
      return {
        ...state,
        isModuleLoading: action.payload,
      };
    case SET_USER_COURSE_DATA_LOADING:
      return {
        ...state,
        isUserCourseDataLoading: action.payload,
      };
    case SET_INITIALIZATION_ERROR:
      return {
        ...state,
        initializationError: action.payload,
      };
    case SET_IS_INITIALIZED:
      return {
        ...state,
        isInitialized: action.payload,
      }
    case CLEAR_APP_CONTEXT:
      return initialState;
    default:
      return state;
  }
}
