import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import {firebaseConfig} from '../util/firebaseConfig'

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore
export const db = firebase.firestore();
export const storage = firebase.storage;
export const auth = firebase.auth;


export async function createUser(firstName, lastName, email, password, type) {
  try {
    const userCredential = await auth().createUserWithEmailAndPassword(email, password);

    const userDocRef = await db.collection('users').doc(userCredential.uid).set({
      firstName: firstName,
      lastName: lastName,
      type: type,
    });

    console.log(`Document Written ${userDocRef}`)
    return true;
  } catch (error) {
    console.log(`Error ${error.code}: ${error.message}`)
    return false;
  }
}

export async function getModuleList(courseID) {
  let modulesRef = db.collection('courses').doc(courseID).collection('modules').orderBy('index');
  
  try {
    let allModuleSnapshot = await modulesRef.get();
    let moduleListData = [];

    allModuleSnapshot.forEach(doc => {
      moduleListData.push({id: doc.id, ...doc.data(), pages: []});
    });

    for (let [index, moduleData] of moduleListData.entries()) {
      let pagesRef = db.collection('courses').doc(courseID).collection('modules').doc(moduleData.id).collection('pages').orderBy('index');
      let pagesSnapshot = await pagesRef.get()
      let pageListData = [];

      pagesSnapshot.forEach((page) => {
        pageListData.push({id: page.id, ...page.data()});
      })

      moduleListData[index].pages = pageListData;
    }
    
    return moduleListData;

  } catch(error) {
      console.log('Error getting module list documents', error);
      return [];
  }
}

export async function getQuizList(courseID) {
  let quizzesRef = db.collection('courses').doc(courseID).collection('quizzes');

  try {
    let allQuizSnapshot = await quizzesRef.get();
    let quizListData = [];

    allQuizSnapshot.forEach((doc) => {
      quizListData.push({
        id: doc.id,
        ...doc.data()
      })
    })

    return quizListData;
  } catch(error) {
    console.log('Error getting quiz list documents', error);
    return[];
  }
}
