import { combineReducers } from "redux";
import appContextReducer from "./appContextReducer";
import moduleDataReducer from "./moduleDataReducer";
import userCourseDataReducer from "./userCourseDataReducer";

const rootReducer = combineReducers({
  appContext: appContextReducer,
  userCourseData: userCourseDataReducer,
  moduleData: moduleDataReducer,
})

export default rootReducer;