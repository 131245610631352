import { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { UserContext } from "../providers/UserProvider";

import LoginPage from "../pages/LoginPage";
import SignUpPage from "../pages/SignUpPage";
import CoursesPage from "../pages/CoursesPage";
import NotFound from "../pages/NotFound";
import AppRouter from "./AppRouter";

export default function MainRouter() {
  const user = useContext(UserContext);

  if (user.loading) {
    return <div />;
  }

  return user.credentials === null ? (
    <Router>
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" replace/>} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signUp" element={<SignUpPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route exact path="/" element={<Navigate to="/courses" replace/>} />
        <Route exact path="/login" element={<Navigate to="/courses" replace/>} />
        <Route exact path="/signUp" element={<Navigate to="/courses" replace/>} />
        <Route exact path="/courses" element={<CoursesPage user={user} />}/>
        <Route path="/app/:courseId/*" element={<AppRouter user={user} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
