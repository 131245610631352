import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainAppBar from "../components/MainAppBar";
import { Box, Container, Grid, Toolbar } from "@mui/material";
import WelcomeCard from "../components/WelcomeCard";
import PropTypes from "prop-types";
import ModuleCard from "../components/ModuleCard";
import BannerLogoCard from "../components/BannerLogoCard";
import Banner from "../assets/images/dashboard_banner.jpg";
import { useDispatch, useSelector } from "react-redux";
import SavvyFooter from "../components/SavvyFooter";
import "../styles/CSSTransition.css";
import { setModuleMenuFocus } from "../actions/moduleDataActions";

export default function DashboardPage({ user, setUserCourseData }) {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const courseData = useSelector((state) => state.userCourseData.courseData);
  const modules = useSelector((state) => state.appContext.modules);
  //const imagesLoaded = loadedImagesCount === modules.length ? true : false;
  const dispatch = useDispatch();
  const isModerator = user.data.role === "moderator" ? true : false;

  const handleModuleClick = async (moduleId) => {
    navigate(`/app/${courseId}/module/${moduleId}`);
  };

  const handleEditModuleClick = (moduleId) => {
    navigate(`/app/${courseId}/module/${moduleId}?mode=edit`);
  };

  const handleCaseStudyClick = (moduleId) => {
    navigate(`/app/${courseId}/module/${moduleId}`);
    dispatch(setModuleMenuFocus("case_studies"));
  };

  const calculateCourseProgress = () => {
    let keys = [];
    for (let i = 0; i < modules.length; i++) {
      if (modules[i].isReady) {
        keys.push(modules[i].id);
      }
    }

    if (keys.length === 0) {
      return {progress: 0, remainder: 0};
    }

    let count = 0;
    for (let i = 0; i < keys.length; i++) {
      if (!courseData[keys[i]]){

      } else if (courseData[keys[i]].isComplete) {
        count++;
      }
    }

    const result = Math.trunc(count/keys.length * 100);
    const remainder = keys.length - count;
    return {result, remainder};
  }

  const calculateModuleProgress = (module) => {
    // Check if there's any module data
    if (!courseData[module.id]) {
      return -1; // Module not started
    }
    // Check Module progress by checking the pages
    let count = 0;
    const length = courseData[module.id].pages.length;
    for (let i = 0; i < length; i++) {
      const isFinished = courseData[module.id].pages[i].isFinished;
      if (isFinished) count++;
    }
    
    return Math.trunc(count/length * 100);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MainAppBar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          backgroundColor: "#EAF8FD",
        }}
      >
        <Toolbar />

        <Container
          disableGutters
          maxWidth="lg"
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFF",
          }}
        >
          <Box sx={{ alignSelf: "stretch" }}>
            <BannerLogoCard imageUri={Banner} />
          </Box>
          <WelcomeCard name={user.data.firstName} progress={calculateCourseProgress()}/>

          <Grid
            container
            width="100%"
            sx={{ backgroundColor: "#FFF", m: 0, p: 0 }}
          >
            {modules.map((module, index) => {
              return isModerator || module.isReady ? (
                <Grid item xs={6} key={`Grid-${index}`} sx={{ px: 2, py: 2 }}>
                  <ModuleCard
                    key={`ModuleCard-${index}`}
                    title={module.title}
                    header={module.header}
                    isReady={module.isReady}
                    userRole={user.data.role}
                    imageUri={module.image}
                    onModuleClick={() => handleModuleClick(module.id)}
                    onEditClick={() => handleEditModuleClick(module.id)}
                    onCaseStudyClick={() => handleCaseStudyClick(module.id)}
                    progress={calculateModuleProgress(module)}
                  />
                </Grid>
              ) : null;
            })}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

/**
 * Initialize Module User Data for User
 * @param {*} moduleId
 * @param {*} courseData // The current course data
 * @param {*} pages
 * @returns
 */
export function initializeModuleUserData(moduleId, courseData, pages) {
  let newCourseData = {
    ...courseData,
  };

  newCourseData[moduleId] = {
    isComplete: false,
    pages: [
      ...Array(pages.length)
        .fill(0)
        .map((x, i) => {
          if (pages[i].type === "quiz") {
            return {
              retryCount: 0,
              enabled: false,
              quizData: null,
              score: null,
            };
          } else {
            return { isFinished: false, enabled: i === 0 ? true : false };
          }
        }),
    ],
  };

  return newCourseData;
}

export function createEditCourseData(moduleId, courseData, pages) {
  let newCourseData = {
    ...courseData,
  };

  newCourseData[moduleId] = {
    isComplete: false,
    pages: [
      ...Array(pages.length)
        .fill(0)
        .map((x, i) => {
          if (pages[i].type === "quiz") {
            return {
              enabled: true,
              quizData: null,
              score: null,
            };
          }

          return { isFinished: false, enabled: true };
        }),
    ],
  };

  return newCourseData;
}

DashboardPage.propTypes = {
  user: PropTypes.object.isRequired,
};
