import { TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export default function OpenEndedQuestion({ question, answer, setAnswer }) {
  const { index } = question;
  const handleChange = (event) => {
    setAnswer(index, event.target.value);
  };

  return (
    <form
      autoComplete="off"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "90%",
        alignItems: "stretch",
      }}
    >
      <TextField
        id="open"
        variant="outlined"
        value={answer}
        onChange={handleChange}
        InputProps={{ classes: { minHeight: 300, maxHeight: 300 } }}
        sx={{ p: 10 }}
        multiline
      />
    </form>
  );
}

OpenEndedQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};
