import React from 'react';
import MainRouter from "./routes/MainRouter";

function App() {

  return (
    <MainRouter/>
  )
}

export default App;
