import React from "react";
import { Button } from "@mui/material";

export default function ProceedButton({ label, onFowardClick, disabled }) {
  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{
        fontWeight: "bold",
        borderRadius: 10,
        minWidth: 100,
        py: 1.5,
        px: 5,
        alignSelf: "center",
      }}
      onClick={onFowardClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
