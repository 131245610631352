import { Box, Button, Container, Fade, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import BannerCard from "../BannerCard";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "../../util/editorJsTools";
import "../../styles/BlockEditor.css";
import { useSelector } from "react-redux";

const ReactEditorJS = createReactEditorJS();

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 24,
  borderRadius: 10,
  border: "2px solid",
  borderColor: "#003057",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#003057",
  },
}));

export default function MainElement({
  page,
  progress,
  updatePageData,
  fowardButton,
}) {
  const editorCore = useRef(null);
  const [ready, setReady] = useState(false);

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleSave = useCallback(async () => {
    const savedData = await editorCore.current.save();
    try {
      await updatePageData(savedData);
    } catch (error) {
      console.error(error);
    }
  }, [updatePageData]);

  const handleReady = () => {
    setReady(true);
  };

  const title = useSelector((state) => state.moduleData.moduleTitle);
  const desc = useSelector((state) => state.moduleData.moduleDesc);
  const header = useSelector((state) => state.moduleData.moduleHeader);
  const image = useSelector((state) => state.moduleData.moduleImage);
  const editable = useSelector((state) => state.moduleData.isEditable);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <BannerCard
        image={image}
        title={header}
        subtitle={title}
      />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <BorderLinearProgress
          sx={{ mx: 8, mt: 4, width: 480 }}
          variant="determinate"
          value={progress}
        />
        <Typography
          sx={{ mt: 1, fontSize: 28, fontWeight: "bold" }}
          textAlign="center"
        >
          {`${progress}% Complete`}
        </Typography>
        <Typography sx={{ mt: 2, mx: 5 }} variant="body1" textAlign="center">
          {desc}
        </Typography>
        {editable && (
          <Button sx={{ my: 1 }} variant="outlined" onClick={handleSave}>
            Save to Database
          </Button>
        )}
        <Fade in={ready}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "stretch",
            }}
          >
            <ReactEditorJS
              tools={EDITOR_JS_TOOLS}
              minHeight={0}
              maxWidth="unset"
              readOnly={!editable}
              onInitialize={handleInitialize}
              defaultValue={{ ...page.data }}
              onReady={handleReady}
            />
            {fowardButton}
          </Box>
        </Fade>
      </Container>
    </Box>
  );
}
