import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export default function RadioQuestion({ question, answer, setAnswer }) {
  const { choices, index } = question;

  return (
    <FormControl component="fieldset" sx={{ mx: 6, maxWidth: 600 }}>
      <RadioGroup
        aria-label="single select"
        name={`q${index}`}
        value={answer}
        onChange={(event) => setAnswer(index, event.target.value)}
      >
        {choices.map((choice, choiceIndex) => (
          <>
            <FormControlLabel
              key={`q${index}_choice${choiceIndex}`}
              value={choice}
              control={<Radio />}
              label={choice}
              sx={{my: 1}}
            />
            <Divider sx={{ borderBottomWidth: 2, borderColor: "primary.main" }}/>
          </>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};
