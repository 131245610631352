export const INITIALIZE_MODULE_DATA = "moduleData/initializeModuleData";
export const SET_SELECTED_INDEX = "moduleData/setSelectedIndex";
export const CLEAR_MODULE_DATA = "moduleData/clear";
export const SET_EDITABLE = "moduleData/setEditable";
export const SET_IS_PREVIEW = "moduleData/setIsPreview";
export const SET_MODULE_DATA_LOADING = "moduleData/setModuleDataLoading";
export const SET_USER_MODULE_DATA_LOADING = "moduleData/setUserModuleDataLoading";
export const SET_MODULE_MENU_FOCUS = "moduleData/setMenuFocus";
export const SET_MODULE_DATA_ERROR = "moduleData/setError"

export const initializeModuleData = (moduleData) => {
  return {
    type: INITIALIZE_MODULE_DATA,
    payload: moduleData,
  };
};

export const setSelectedIndex = (index) => {
  return {
    type: SET_SELECTED_INDEX,
    payload: index,
  };
};

export const setEditable = (isEditable) => {
  return {
    type: SET_EDITABLE,
    payload: isEditable,
  };
};

export const setPreview = (isPreview) => {
  return {
    type: SET_IS_PREVIEW,
    payload: isPreview,
  };
};

export const clearModuleData = () => {
  return {
    type: CLEAR_MODULE_DATA,
  };
};

export const setModuleDataLoading = (isLoading) => {
  return {
    type: SET_MODULE_DATA_LOADING,
    payload: isLoading,
  };
};

export const setUserModuleDataLoading = (isLoading) => {
  return {
    type: SET_USER_MODULE_DATA_LOADING,
    payload: isLoading,
  }
}

export const setModuleMenuFocus = (focus) => {
  return {
    type: SET_MODULE_MENU_FOCUS,
    payload: focus,
  };
};

export const setModuleDataError = (error) => {
  return {
    type: SET_MODULE_DATA_ERROR,
    payload: error
  }
}
