import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import VideoElement from "./PageElements/VideoElement";
import BlockElement from "./PageElements/BlockElement";
import MainElement from "./PageElements/MainElement";
import { getCanProceedInitialState } from "../util/pageHelper";
import QuizElement from "./PageElements/QuizElement";
import { useDispatch, useSelector } from "react-redux";
import PostModuleModal from "./PostModuleModal";
import { updatePageData, updateUserCourseData } from "../api/course";
import { cloneDeep } from "lodash";
import { setSelectedIndex } from "../actions/moduleDataActions";
import CaseStudyElement from "./PageElements/CaseStudyElement";
import ImageElement from "./PageElements/ImageElement";
import ProceedButton from "./ProceedButton";

function calcModuleProgress(userModuleCourseData) {
  let count = 0;
  for (const page of userModuleCourseData.pages) {
    if (page.isFinished) {
      count++;
    }
  }
  return Math.trunc((count / userModuleCourseData.pages.length) * 100);
}

export default function RenderPageElement({ uid }) {
  const courseId = useSelector((state) => state.appContext.courseId);
  const moduleId = useSelector((state) => state.moduleData.moduleId);
  const pages = useSelector((state) => state.moduleData.pages);
  const selectedIndex = useSelector((state) => state.moduleData.selectedIndex);
  const editable = useSelector((state) => state.moduleData.isEditable);
  const courseData = useSelector((state) => state.userCourseData.courseData);
  const dispatch = useDispatch();

  const [canProceed, setCanProceed] = useState(false);
  const [openPostModuleModal, setOpenPostModuleModal] = useState(false);

  const userPageData = courseData[moduleId].pages[selectedIndex];
  const page = pages[selectedIndex];
  const pagesLength = pages.length;
  const fowardText = page.index < pagesLength - 1 ? "Proceed" : "Finish";

  useEffect(() => {
    setCanProceed(
      getCanProceedInitialState(editable, page.type, userPageData.isFinished)
    );
  }, [editable, page, userPageData.isFinished]);

  const handleUpdatePageData = async (data) => {
    await updatePageData(courseId, moduleId, page.id, data);
  };

  const onFowardClick = async () => {
    if (!canProceed) return; // Check if we're allowed to proceed
    if (page.isFinished || editable)
      dispatch(setSelectedIndex(selectedIndex + 1)); // If we're already finished go to next page

    // Prepare write to database
    // First, create a deep copy and set the current page to finish
    let newCourseData = cloneDeep(courseData);
    newCourseData[moduleId].pages[selectedIndex].isFinished = true;
    // Enable the next page if we're not the last page
    if (selectedIndex < pagesLength - 1)
      newCourseData[moduleId].pages[selectedIndex + 1].enabled = true;

    try {
      await updateUserCourseData(uid, courseId, newCourseData);
    } catch (error) {
      console.error(error);
    }

    // If we're on the last page open the postModuleModal
    if (selectedIndex < pagesLength - 1)
      dispatch(setSelectedIndex(selectedIndex + 1));
    else setOpenPostModuleModal(true);
  };

  /**
   * Determines the page element component to render base on the given page type
   * @param type  Page Element Type
   * @returns A JSX Page Element
   */
  const renderPageElement = (page) => {
    const fowardButton = (
      <ProceedButton
        onFowardClick={() => onFowardClick(canProceed)}
        label={fowardText}
        disabled={!canProceed}
      />
    );
    const key = `${page.type}-${page.index}`;

    switch (page.type) {
      case "main":
        const moduleProgress = calcModuleProgress(courseData[moduleId]);
        return (
          <MainElement
            key={key}
            page={page}
            progress={moduleProgress}
            updatePageData={handleUpdatePageData}
            fowardButton={fowardButton}
          />
        );
      case "block":
        return (
          <BlockElement key={key} data={page} fowardButton={fowardButton} />
        );
      case "video":
        return (
          <VideoElement
            key={key}
            page={page}
            onEnd={() => setCanProceed(true)}
            fowardButton={fowardButton}
          />
        );
      case "case_study":
        return (
          <VideoElement key={key} page={page} onEnd={() => null} fowardButton={fowardButton} />
        );
      case "quiz":
        return (
          <QuizElement
            key={key}
            uid={uid}
            page={page}
            setCanProceed={(canProceed) => setCanProceed(canProceed)}
            fowardButton={fowardButton}
          />
        );
      case "image":
        return (
          <ImageElement key={key} page={page} fowardButton={fowardButton} />
        );
      default:
        return <h3 style={{ textAlign: "center" }}>No Content Detected</h3>;
    }
  };

  const decideRenderFowardButton = () => {
    if (page.type === "quiz" && canProceed === false) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {renderPageElement(page)}
      <PostModuleModal
        open={openPostModuleModal}
        handleClose={() => setOpenPostModuleModal(false)}
        courseId={courseId}
      />
    </>
  );
}

RenderPageElement.propTypes = {
  type: PropTypes.string,
};
