import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
  Fade,
} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DraftsIcon from "@mui/icons-material/Drafts";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function ModuleCard({
  title,
  header,
  isReady,
  userRole,
  imageUri,
  onModuleClick,
  onEditClick,
  onCaseStudyClick,
  progress,
}) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 10,
    border: "2px solid",
    borderColor: "#003057",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#FFF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#003057",
    },
  }));

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);
  const [imageIn, setImageIn] = useState(false);
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const onImageLoad = () => {
    setImageIn(true);
  };

  return (
    <Card sx={{ display: "flex", flexDirection: "column", height: 440 }}>
      <Box sx={{ backgroundColor: "primary.main" }} height={200}>
        <Fade in={imageIn}>
          <CardMedia
            sx={{ height: "100%" }}
            component="img"
            image={imageUri}
            onLoad={onImageLoad}
            alt="Module Image"
          />
        </Fade>
      </Box>
      <Box sx={{ display: "flex" }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography sx={{ fontSize: 34, fontWeight: "bold" }}>
            {header}
          </Typography>
          <Typography sx={{ fontSize: 28, fontWeight: "bold" }}>
            {title}
          </Typography>
          {progress === -1 ? (
            <Typography>Not Started</Typography>
          ) : (
            <BorderLinearProgress
              sx={{ flex: 1, mr: 1 }}
              variant="determinate"
              value={progress}
            />
          )}
          <Button
            sx={{
              mt: 2,
              mb: 1,
              fontWeight: "bold",
              borderRadius: 8,
              borderColor: "#003057",
            }}
            variant="outlined"
            onClick={onCaseStudyClick}
          >
            Case Studies
          </Button>
          {!isReady && (
            <Box sx={{ mt: 1, display: "flex" }}>
              <DraftsIcon />{" "}
              <Typography sx={{ fontWeight: "bold", mx: 1 }}>Draft</Typography>
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ marginBottom: "auto", alignSelf: "center" }}>
          <IconButton onClick={onModuleClick}>
            <PlayCircleOutlineIcon
              sx={{ color: "text.primary", fontSize: 54 }}
            />
          </IconButton>
          {userRole === "moderator" && (
            <>
              <IconButton
                id="module-menu-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleMenuClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="module-menu"
                anchorEl={menuAnchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={onEditClick}>Edit</MenuItem>
                <MenuItem onClick={handleMenuClose}>Remove</MenuItem>
              </Menu>
            </>
          )}
        </CardActions>
      </Box>
    </Card>
  );
}

ModuleCard.defaultProps = {
  imageUri:
    "https://i.pinimg.com/originals/60/0a/62/600a62af82615ce2b7c4b161089087ce.jpg",
};
