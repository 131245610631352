import { Box } from "@mui/system";
import React from "react";
import PoweredBySavvyLogo from "../assets/icons/powered_by_savvy.svg";
import SavvyGradientLine from "../assets/images/savvy_gradient_line.png";

export const FOOTER_HEIGHT = 56

export default function SavvyFooter() {
  return (
    <Box
      sx={{
        width: "100%",
        height: FOOTER_HEIGHT,
        position: "fixed",
        bottom: 0,
        backgroundColor: "#252626",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 100000
      }}
    >
      <Box
        component="img"
        src={SavvyGradientLine}
        sx={{ width: "100vw", height: 5 }}
      />
      <Box
        component="img"
        src={PoweredBySavvyLogo}
        sx={{ my: 1 }}
        alt="Powered By Savvy Marketers LLC"
      />
    </Box>
  );
}
